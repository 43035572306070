<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Pengujian Kalibrasi'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-1">
              <b-button variant="outline-dark" @click="$router.push('/pengujiankalibrasi/add')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                @row-clicked="info"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nomor_ppj"
                        type="search"
                        placeholder="Cari Nomor PJJ..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.tanggal"
                        type="date"
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-3"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_barang"
                        type="search"
                        placeholder="Cari Nama Barang..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.jumlah"
                        type="number"
                        placeholder="Cari Jumlah..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-select
                        id="filter-5"
                        size="sm"
                        @input="loadData"
                        v-model="search.status"
                        :options="status"
                      >
                      </b-form-select>
                    </b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(tanggal)="data">
                  {{ moment(data.item.tanggal) }}
                </template>

                <template #cell(status)="data">
                  <span v-if="data.item.status == 1" style="color: blue">Sent</span>
                  <span v-else-if="data.item.status == 2" style="color: orange">Proses</span>
                  <span v-else-if="data.item.status == 3" style="color: green">Approve</span>
                  <span v-else-if="data.item.status == 4" style="color: red">Reject</span>
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="info(data.item, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="
                        $router.push('/pengujiankalibrasi/edit/' + data.item.id_pengujian_kalibrasi)
                      "
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <b-modal v-model="modal" okOnly ok-title="Tutup" ok-variant="success">
            <b-row>
              <b-col>
                <span v-html="contentModal"></span>
              </b-col>
            </b-row>
          </b-modal>
          <b-modal id="info-modal" size="lg" title="Detail" ok-only @hide="resetInfoModal">
            <b-row class="mb-1">
              <b-col sm="3" class="text-sm-right"><b>Nomor PJJ :</b></b-col>
              <b-col>{{ modalInfo.nomor_ppj }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="3" class="text-sm-right"><b>Tanggal :</b></b-col>
              <b-col>{{ modalInfo.tanggal }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="3" class="text-sm-right"><b>Nama Barang :</b></b-col>
              <b-col>{{ modalInfo.nama_barang }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="3" class="text-sm-right"><b>Jumlah :</b></b-col>
              <b-col>{{ modalInfo.jumlah }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="3" class="text-sm-right"><b>Satuan :</b></b-col>
              <b-col>{{ modalInfo.satuan }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="3" class="text-sm-right"><b>Status :</b></b-col>
              <b-col>
                <span v-if="modalInfo.status == 1" style="color: blue">Sent</span>
                <span v-else-if="modalInfo.status == 2" style="color: orange">Proses</span>
                <span v-else-if="modalInfo.status == 3" style="color: green">Approve</span>
                <span v-else-if="modalInfo.status == 4" style="color: red">Reject</span>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import * as moment from 'moment'
import 'moment/locale/id'

export default {
  data() {
    return {
      sortBy: 'tanggal',
      sortDesc: true,
      sort_label: 'DESC',
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'nomor_ppj', label: 'Nomor PPJ', sortable: true },
        { key: 'tanggal', label: 'Tanggal', sortable: true },
        { key: 'nama_barang', label: 'Nama Barang', sortable: true },
        { key: 'jumlah', label: 'Jumlah', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: {
        nomor_pjj: '',
        tanggal: '',
        nama_barang: '',
        jumlah: 0,
        status: '',
      },
      status: [
        { value: null, text: '-- Pilih Status --' },
        { value: 1, text: 'Sent' },
        { value: 2, text: 'Diproses' },
        { value: 3, text: 'Approve' },
        { value: 4, text: 'Reject' },
      ],
      modalInfo: {
        nomor_ppj: null,
        tanggal: null,
        nama_barang: null,
        jumlah: null,
        satuan: null,
        status: null,
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,

      modal: false,
      contentModal: '',
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Pengujian Kalibrasi', route: '' },
      { title: 'Pengujian Kalibrasi' },
    ])
  },
  created() {
    var self = this

    ApiService.get(
      '/pengujiankalibrasi?page=' +
        this.currentPage +
        '&nomor_pjj=' +
        this.search.nomor_pjj +
        '&tanggal=' +
        this.search.tanggal +
        '&nama_barang=' +
        this.search.nama_barang +
        '&jumlah=' +
        this.search.jumlah +
        '&status=' +
        this.search.status +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    moment: function(date) {
      moment.locale('id')
      return moment(date).format('DD/MM/YYYY')
    },
    info(item, button) {
      this.modalInfo.nomor_ppj = item.nomor_ppj
      this.modalInfo.tanggal = item.tanggal
      this.modalInfo.nama_barang = item.nama_barang
      this.modalInfo.jumlah = item.jumlah
      this.modalInfo.satuan = item.satuan
      this.modalInfo.status = item.status
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    resetInfoModal() {
      this.modalInfo.nomor_ppj = null
      this.modalInfo.tanggal = null
      this.modalInfo.nama_barang = null
      this.modalInfo.jumlah = null
      this.modalInfo.satuan = null
      this.modalInfo.status = null
    },
    modalShow(content) {
      this.contentModal = content
      this.modal = true
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/pengujiankalibrasi?page=' +
          this.currentPage +
          '&nomor_pjj=' +
          this.search.nomor_pjj +
          '&tanggal=' +
          this.search.tanggal +
          '&nama_barang=' +
          this.search.nama_barang +
          '&jumlah=' +
          this.search.jumlah +
          '&status=' +
          this.search.status +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.item.id_pengujian_kalibrasi
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/pengujiankalibrasi/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
